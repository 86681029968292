<section class="header">
  <h2>Safecility Developer Site</h2>
</section>
<mat-drawer-container class="sidenav-container">
  <mat-drawer mode="side" opened>
    <section *ngIf="loggedIn">
      <ul>
        <li class="nav-item" routerLinkActive="active-button">
          <a routerLink="application/list" routerLinkActive="active-link">
            <mat-icon mat-list-icon>home</mat-icon>
          </a>
          <a routerLink="application/list" routerLinkActive="active-link">
            <span class="link-text">OAuth</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active-button">
          <a routerLink="application/basic" routerLinkActive="active-link">
            <mat-icon mat-list-icon>developer_board</mat-icon>
          </a>
          <a routerLink="application/basic" routerLinkActive="active-link">
            <span class="link-text">OpenAPI</span>
          </a>
        </li>
      </ul>
    </section>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>

</mat-drawer-container>
