import {Component, OnInit} from '@angular/core';
import {UserAuthService} from "safecility-core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'safecility developer site';
  loggedIn = false;
  url;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAuthService: UserAuthService) {
  }

  ngOnInit() {
    this.router.events.subscribe( (x: any) => {
      console.log('got router event ', x)
      if (x.url) {
        this.url = x.url;
        console.log('url is ', this.url);
      }
    })

    this.userAuthService.userState.subscribe(
      u => {
        console.log('got user state', u);
        this.loggedIn = true;
    }, err => {
        console.log('got err on user state', err)
      })
  }
}
