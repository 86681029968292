import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DebugService, UserAuthService } from 'safecility-core';
import { User } from 'safecility-models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private debugService: DebugService,
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.userAuthService.getUser().pipe(
      map((user: User) => {
        this.debugService.debug('AuthGuard-canActivate-getUser', user);
        this.userAuthService.setUser(user);
        if (!next.data.expectedPermission) {
          return true;
        }
        this.debugService.debug('AuthGuard-canActivate-getUser-expected permission', next.data.expectedPermission);
        return !!user.permissions[next.data.expectedPermission];
      }),
      catchError((err) => {
        this.debugService.warn('AuthGuard-canActivate', `service did not authorize ${err}`);
        this.router.navigateByUrl('/login').then( _ => console.log('redirected'));
        return of(false);
      })
    );
  }

}
