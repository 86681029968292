import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  { path: '',
    redirectTo: 'application/list',
    pathMatch: 'prefix' },
  { path: 'login', component: LoginComponent},
  {
    path: 'application',
    canActivate: [AuthGuard],
    loadChildren: '../openapi/openapi.module#OpenapiModule'
  },
  {
    path: 'public',
    loadChildren: '../public/public.module#PublicModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
